import { Icon } from 'components/Icon/Icon'
import { ReactNode } from 'react'
import styles from './ModalPage.module.scss'

type Props = {
    children: ReactNode,
    onClose?: () => void,
    hideCross: boolean,
}

export function ModalPage(props: Props) {

    return (
        <div className={styles['modal']}>
            {!props.hideCross && (
                <div className={styles['modal__navbar']}>
                    <div className={styles['cross']} onClick={props.onClose}>
                        <Icon name='cross' />
                    </div>
                </div>
            )}
            <div className={styles['wrapper']}>
                <div className={styles['form']}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

ModalPage.defaultProps = {
    hideCross: false
};

