import React from 'react'
import styles from './MoneyTarget.module.scss'
import { Button } from 'components/Button/Button'
import { PopupEditTarget } from './PopupEditTarget/PopupEditTarget'

type Props = {
    amount: number | null,
    isPreview?: boolean,
}

export function MoneyTarget(props: Props) {
    const [showPopup, setShowPopup] = React.useState(false)

    const buttonText = props.amount ? 'Change target' : 'Set target value'
    const labelText = props.amount ? (<span>Your <br /> target</span>) : 'Wait until your money grows more'

    function closePopup() {
        setShowPopup(false)
    }

    function openPopup() {
        setShowPopup(true)
    }


    return (
        <>
            <div className={styles['wrapper']}>
                <div className={styles['balance']}>
                    <span className={styles['balance__label']}>{labelText}</span>
                    {props.amount && <span className={styles['balance__amount']}>${props.amount}</span>}
                </div>
                <div className={styles['button']}>
                    <Button size='m' type='border' onClick={openPopup} isPreview={props.isPreview}>
                        {buttonText}
                    </Button>
                    <span>Set target value and we'll notify you once your earnings meet it</span>
                </div>
            </div>
            {showPopup && <PopupEditTarget onClose={closePopup} />}
        </>
    );
}

