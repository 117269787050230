import React from 'react';
import styles from './LinkInput.module.scss';
import {Icon, IconType} from "../Icon/Icon";
import classnames from "classnames";
import {readUserByLinkOrNull} from "../userAPI";
import {useWeb3} from "../Web3Context/Web3Context";

type Props = {
    linkPrefix: string,
    label: string,
    name: string,
    value: string,
    onChange?: (newLink: string) => void,
}

type ValidityStatus = "checking" | "valid" | "invalid";
type ErrorState = "none" | "linkEmpty" | "linkTaken";

const DB_CHECK_DELAY_MS = 1000;

export function LinkInput(props: Props) {
    const [validityStatus, setValidityStatus] = React.useState<ValidityStatus>("checking");
    const [errorState, setErrorState] = React.useState<ErrorState>("none");

    const { userAddress } = useWeb3();

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setValidityStatus("checking");
        props.onChange(event.currentTarget.value);
    }

    const classNames = classnames({
        [styles['input']]: true,
    })

    function checkedIcon(): IconType {
        switch(validityStatus) {
            case "checking":
                return "eye";
            case "valid":
                return "check"
            case "invalid":
                return "error";
        }
    }

    async function isLinkAvaiable(link: string): Promise<boolean> {
        if (link === "") {
            return false;
        }
        const maybeUser = await readUserByLinkOrNull(link.toLowerCase());
        if (!maybeUser) {
            setErrorState("none");
            return true;
        } else if (maybeUser.address.toLowerCase() === userAddress.toString().toLowerCase()) {
            setErrorState("none");
            return true;
        } else {
            setErrorState("linkTaken");
            return false;
        }
    }

    const handleBlur = () => {
        if (props.value === "") {
            props.onChange(userAddress.toString().toLowerCase());
        }
    }

    React.useEffect(() => {
        const debounceTimer = setTimeout(async () => {
            const available = await isLinkAvaiable(props.value);
            setValidityStatus(available ? "valid" : "invalid");
        }, DB_CHECK_DELAY_MS);

        return () => clearTimeout(debounceTimer);
    }, [props.value]);

    return (
        <div className={styles['wrapper']}>
            <label htmlFor={props.name} className={styles['label']}>
                {props.label}
            </label>
            <div className={styles['container']}>
                <span className={styles['linkPrefix']}>{props.linkPrefix}</span>
                <input
                    id={props.name}
                    name={props.name}
                    className={classNames}
                    value={props.value}
                    onChange={handleChange}
                    placeholder={userAddress}
                    onBlur={handleBlur}
                />
                <div className={styles['icon']}>
                    <Icon name={checkedIcon()}/>
                </div>
            </div>
            {(errorState === "linkEmpty") && <div className={styles['error']}>Link cannot be empty. Please write something!</div>}
            {(errorState === "linkTaken") && <div className={styles['error']}>Unfortunately, this link is already taken.</div>}
        </div>
    )
}