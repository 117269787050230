import { formatNumberWithCommas, formatNumberWithCommasAndDecimal } from "components/helpers"

export const DRIZZLES_COLUMN_WIDTH = {
    patron: '390px',
    beneficiary: '390px',
    currency: '100px',
    deposit: '100px',
}

export const DRIZZLES_HEADERS = [
    {
        id: 'patron',
        name: 'Patron',
        width: DRIZZLES_COLUMN_WIDTH.patron,
    },
    {
        id: 'beneficiary',
        name: 'Beneficiary',
        width: DRIZZLES_COLUMN_WIDTH.beneficiary,
    },
    {
        id: 'currency',
        name: 'Currency',
        width: DRIZZLES_COLUMN_WIDTH.currency,
    },
    {
        id: 'deposit',
        name: 'Deposit size',
        width: DRIZZLES_COLUMN_WIDTH.deposit,
    },
]

export const DRIZZLES_ANALYTICS = [
    {
        id: '1',
        name: 'Total value',
        number: formatNumberWithCommas('', '8500'),
        description: 'Total value deposited',
        helpText: 'help text about Total value deposited',

    },
    {
        id: '2',
        name: 'Total interest',
        number: formatNumberWithCommasAndDecimal(9670),
        description: 'Total interest generated',
        helpText: 'help text about Total interest generated'
    },
    {
        id: '3',
        name: 'Total beneficiaries',
        number: '3',
        description: 'Total number of beneficiaries',
        helpText: 'help text about Total number of beneficiaries',
        isCurrency: false,
    }
]

export const DRIZZLES_MOCK_DATA = {
    headers: DRIZZLES_HEADERS,
    columnWidth: DRIZZLES_COLUMN_WIDTH,
    patrons: [
        {
            beneficiary: 'Aleksandr F',
            currency: 'USDC',
            deposit: formatNumberWithCommas('USDC', '1500'),
        },
    ]
}

/*
export const SUPPORT_COLUMN_WIDTH = {
    id: '80px',
    recepient: '100px',
    deposit: '120px',
    interest: '60px',
    claimed: '100px',
    available_w: '100px',
    status: '140px',
    action: '140px'
}

export const SUPPORT_HEADERS = [
    {
        id: 'id',
        name: 'Number',
        width: SUPPORT_COLUMN_WIDTH.id,
    },
    {
        id: 'recepient',
        name: 'Recepient',
        width: SUPPORT_COLUMN_WIDTH.recepient,
    },
    {
        id: 'deposit',
        name: 'Deposit',
        width: SUPPORT_COLUMN_WIDTH.deposit,
    },
    {
        id: 'interest',
        name: 'Interest',
        width: SUPPORT_COLUMN_WIDTH.interest,
    },
    {
        id: 'claimed',
        name: 'Claimed',
        width: SUPPORT_COLUMN_WIDTH.claimed,
    },
    {
        id: 'available_w',
        name: 'Available for withdrawal',
        width: SUPPORT_COLUMN_WIDTH.available_w,
    },
    {
        id: 'status',
        name: 'Status',
        width: SUPPORT_COLUMN_WIDTH.status,
    },
    {
        id: 'action',
        name: '',
        width: SUPPORT_COLUMN_WIDTH.action,
    },
]

export const SUPPORT_ANALYTICS = [
    {
        id: '32433244',
        name: 'Total value',
        number: formatNumberWithCommas('', '8500'),
        description: 'Total value deposited',
        helpText: 'help text about Total value deposited',

    },
    {
        id: '324344343424',
        name: 'Total interest',
        number: formatNumberWithCommasAndDecimal(9670),
        description: 'Total interest generated',
        helpText: 'help text about Total interest generated'
    },
    {
        id: '324434535324',
        name: 'Total beneficiaries',
        number: '3',
        description: 'Total number of beneficiaries',
        helpText: 'help text about Total number of beneficiaries',
        isCurrency: false,
    }
]

export const SUPPORT_MOCK_DATA = {
    headers: SUPPORT_HEADERS,
    columnWidth: SUPPORT_COLUMN_WIDTH,
    patrons: [
        {
            id: '7358539',
            recepient: 'Aleksandr F',
            deposit: formatNumberWithCommas('USDC', '1500'),
            interest: '2%',
            claimed: formatNumberWithCommasAndDecimal(1100, '$'),
            available_w: formatNumberWithCommasAndDecimal(1400, '$'),
            status: PATRON_STATUS.FAILED,
            action: 'true'
        },
        {
            id: '7578539',
            recepient: 'Aleksandr F',
            deposit: formatNumberWithCommas('USDC', '1500'),
            interest: '2%',
            claimed: formatNumberWithCommasAndDecimal(1100, '$'),
            available_w: formatNumberWithCommasAndDecimal(1400, '$'),
            status: PATRON_STATUS.IN_PROGRESS,
            action: 'true'
        },
        {
            id: '758539',
            recepient: 'Aleksandr F',
            deposit: formatNumberWithCommas('USDC', '1500'),
            interest: '2%',
            claimed: formatNumberWithCommasAndDecimal(1100, '$'),
            available_w: formatNumberWithCommasAndDecimal(1400, '$'),
            status: PATRON_STATUS.WITHDREW,
            action: 'true'
        },
        {
            id: '758549',
            recepient: 'Ivanov F',
            deposit: formatNumberWithCommas('USDC', '1300'),
            interest: '3%',
            claimed: formatNumberWithCommasAndDecimal(1500, '$'),
            available_w: formatNumberWithCommasAndDecimal(1300, '$'),
            status: PATRON_STATUS.CLAIMED,
            action: 'true'
        },
        {
            id: '758399',
            recepient: 'Olegov D',
            deposit: formatNumberWithCommas('USDC', '500'),
            interest: '4%',
            claimed: formatNumberWithCommasAndDecimal(2500, '$'),
            available_w: formatNumberWithCommasAndDecimal(1200, '$'),
            status: PATRON_STATUS.UNCLAIMED,
            action: 'true'
        },
    ]
}
 */