import { TypeHeader } from 'components/Dashboard/consts'
import styles from './RowHeader.module.scss'

type Props = {
    headers: TypeHeader[]
}

export function RowHeader(props: Props) {
    return (
        <div className={styles['wrapper']}>
            {props.headers.map((header) => {
                return (
                    <div
                        className={styles['item']}
                        key={header.id}
                        style={{ width: header.width }}>
                        {header.name}
                    </div>
                )
            })}
        </div>
    );
}

