import { ColumnWidth, PATRON_STATUS, PatronType } from 'components/Dashboard/consts'
import { Button } from 'components/Button/Button'
import styles from './RowItem.module.scss'
import { v4 as uuidv4 } from 'uuid'
import classnames from 'classnames'
import {EthAddress} from "../../../EthAddress/EthAddress";
import {ethers} from "ethers";
import {useWeb3} from "../../../Web3Context/Web3Context";
import SecureLocalStorage from "../../../SecureLocalStorage";
import {useEffect, useState} from "react";
import {CopyButton} from "../../../CopyButton/CopyButton";


type Props = {
    patron: PatronType,
    columnWidth: ColumnWidth,
    onOpenWithdraw?: (beneficiary: ethers.AddressLike, amount: bigint) => void,
}


export function RowItem(props: Props) {
    const [isCopied, setIsCopied] = useState(false);
    const [maybeClaimLink, setMaybeClaimLink] = useState<string | null>(null);
    const { usdcHumanToEth } = useWeb3();

    const claimLink = () => {
        return(process.env.REACT_APP_BASE_URL + '/claim/' + maybeClaimLink);
    }

    useEffect(() => {
        (async () => {
            if (!!props.patron['beneficiary'] && ethers.isAddress(props.patron['beneficiary'])) {
                setMaybeClaimLink(await SecureLocalStorage.getClaimLink(props.patron['beneficiary'] as string));
            }
        })();
    }, [props.patron['beneficiary']]);

    return (
        <div className={styles['wrapper']}>
            {Object.keys(props.patron).map((itemName) => {

                if (itemName === 'action') {
                    return (
                        <div
                            key={uuidv4()}
                            className={styles['item']}
                            style={{ width: props.columnWidth[itemName] }}
                        >
                            <div className={styles["actionButtonContainer"]}>
                                {props.patron['status'] === PATRON_STATUS.FAILED ? (
                                    <Button
                                        size='xs'
                                        type='primary'
                                        stretch={false}
                                    >
                                        Send again
                                    </Button>
                                ) : (
                                    <div className={styles['actionButton']}>
                                        <Button
                                            size='xs'
                                            type='border'
                                            stretch={false}
                                            onClick={
                                                () => props.onOpenWithdraw(
                                                    String(props.patron['beneficiary']),
                                                    usdcHumanToEth(props.patron['deposit'])
                                                )}
                                        >
                                            Withdraw
                                        </Button>
                                    </div>
                                )}
                                {!!maybeClaimLink &&
                                <div className={styles['claimButtonWrapper']}>
                                    <CopyButton textToCopy={claimLink()} />
                                </div>
                                }
                            </div>
                        </div>
                    )
                }

                if (itemName === 'status') {
                    const statusClassNames = classnames({
                        [styles['status']]: true,
                        [styles['status_green']]: props.patron[itemName] === PATRON_STATUS.WITHDREW,
                        [styles['status_blue']]: props.patron[itemName] === PATRON_STATUS.CLAIMED,
                        [styles['status_orange']]: props.patron[itemName] === PATRON_STATUS.UNCLAIMED,
                        [styles['status_red']]: props.patron[itemName] === PATRON_STATUS.FAILED,
                        [styles['status_gray']]: props.patron[itemName] === PATRON_STATUS.IN_PROGRESS,
                    })

                    return (
                        <div
                            key={uuidv4()}
                            className={styles['item']}
                            style={{ width: props.columnWidth[itemName] }}
                        >
                            <div className={statusClassNames}>
                                {props.patron[itemName]}
                            </div>
                        </div>
                    )
                }

                if ((itemName === 'from') || (itemName === 'patron') || (itemName === 'beneficiary')) {
                    return(
                    <div
                        key={uuidv4()}
                        className={styles['item']}
                        style={{ width: props.columnWidth[itemName] }}
                    >
                        <EthAddress
                            address={String(props.patron[itemName])}
                            isClaimLink={!!maybeClaimLink}
                        />
                    </div>
                    );
                }

                return (
                    <div
                        key={uuidv4()}
                        className={styles['item']}
                        style={{ width: props.columnWidth[itemName] }}
                    >
                        {props.patron[itemName]}
                    </div>
                )
            })}
        </div>
    );
}

