import { useNavigate } from 'react-router-dom'
import styles from './AuthNavBar.module.scss'
import { Icon } from 'components/Icon/Icon'
import { Button } from 'components/Button/Button'

type Props = {
    authType: 'login' | 'signup';
}

export function AuthNavBar(props: Props) {
    const navigate = useNavigate();

    const buttonText = props.authType  === 'login' ? 'Get started' : 'Log in';
    const navigateUrl = props.authType === 'login' ? 'signup' : 'login';

    function navigateTo() {
        navigate(`/auth/${navigateUrl}`)
    }

    return (
        <div className={styles['wrapper']}>
            <Icon name='drizzle' />
            <Button
                size='s'
                type='border'
                stretch={false}
                onClick={navigateTo}
            >
                {buttonText}
            </Button>
        </div>
    )
}

