import { Icon } from 'components/Icon/Icon'
import { ReactNode } from 'react'
import styles from './Modal.module.scss'

type Props = {
    children: ReactNode;
    onClose: () => void;
}

export function Modal(props: Props) {

    return (
        <div className={styles['modal']}>
            <div className={styles['wrapper']}>
                <div className={styles['form']}>
                    <div className={styles['cross']} onClick={props.onClose}>
                        <Icon name='cross' />
                    </div>
                    {props.children}
                </div>
            </div>
        </div >
    );
}

