import React from 'react'
import { Button } from 'components/Button/Button'
import styles from './SignupStep2.module.scss'


type Props = {
    onSupport: () => void;
    onReceive: () => void;
}

export function SignupStep2(props: Props) {
    return (
        <div className={styles['wrapper']}>
            <div className={styles['title']}>
                Support
                <br />
                or receive?</div>
            <div className={styles['description']}>
                Do you want to receive help or support others?
                <br />
                Later you can easily switch between the profile of the Patron and the Creator.
            </div>
            <div className={styles['buttons']}>
                <Button
                    size='m'
                    type='dark'
                    iconName='forward'
                    iconRight
                    onClick={props.onSupport}
                >
                    Support
                </Button>
                <Button
                    size='m'
                    type='dark'
                    iconName='forward'
                    iconRight
                    onClick={props.onReceive}
                >
                    Receive
                </Button>
            </div>
        </div >
    );
}

