import { formatNumberWithCommas, formatNumberWithCommasAndDecimal } from "components/helpers"

export const REQUEST_COLUMN_WIDTH = {
    'from': '390px',
    'currency': '100px',
    'amount': '200px',
}

export const REQUEST_HEADERS = [
    {
        id: 'from',
        name: 'Patron',
        width: REQUEST_COLUMN_WIDTH.from,
    },
    {
        id: 'currency',
        name: 'Currency',
        width: REQUEST_COLUMN_WIDTH.currency,
    },
    {
        id: 'amount',
        name: 'Deposit size',
        width: REQUEST_COLUMN_WIDTH.amount,
    },
]

export const REQUEST_ANALYTICS = [
    {
        id: '324324',
        name: 'Total value',
        number: formatNumberWithCommas('', '6587'),
        description: 'Total value deposited',
        helpText: 'Combined amount of money committed to Drizzle in your name. You receive interest from that amount',
    },
    {
        id: '324343424',
        name: 'Total outstanding',
        number: formatNumberWithCommasAndDecimal(197),
        description: 'Total outstanding interest',
        helpText: 'The interest that accumulated to date and can be claimed now'
    },
    {
        id: '324535324',
        name: 'Total earnings',
        number: formatNumberWithCommasAndDecimal(6597),
        description: 'Total earnings to date',
        helpText: 'Total interest earned by your profile to date, including previously claimed'
    },
    {
        id: '3243535324',
        name: 'Number of patrons',
        number: '14',
        description: 'Number of patrons',
        helpText: 'How many unique people committed to Drizzle in your name',
        isCurrency: false
    }]

export const REQUEST_MOCK_DATA = {
    headers: REQUEST_HEADERS,
    columnWidth: REQUEST_COLUMN_WIDTH,
    patrons: [
        {
            from: 'Arya Stark',
            currency: 'USDC',
            amount: '300',
        },
        {
            from: 'Bran Stark',
            currency: 'USDC',
            amount: '750',
        },
        {
            from: 'Robb Stark',
            currency: 'USDC',
            amount: '1050',
        },
        {
            from: 'Sansa Stark',
            currency: 'USDC',
            amount: '250',
        },
    ]
}