import { Button } from 'components/Button/Button'
import styles from './LoginContent.module.scss'
import { useNavigate } from 'react-router-dom'
import { Attention } from 'components/Attention/Attention'
import React from 'react'
import {useWeb3, ConnectMetamaskResponse} from "../../../../components/Web3Context/Web3Context";

const DO_WE_SHOW_GOOGLE = false;
const DESTINATION_AFTER_LOGIN = '/support';
const ROUTE_SIGNUP = '/auth/signup';

type Props = {}

export function LoginContent() {
    const navigate = useNavigate()
    const [metamaskRejected, setMetamaskRejected] = React.useState(false)

    const { connectWeb3 } = useWeb3();

    async function connectMetamask() {
        const response: ConnectMetamaskResponse = await connectWeb3();
        if (response === "rejected") {
            setMetamaskRejected(true);
        } else if (response === "success") {
            navigate(DESTINATION_AFTER_LOGIN);
        }
    }

    function navigateToSignUp() {
        navigate(ROUTE_SIGNUP);
    }

    return (
        <div className={styles['wrapper']}>
            <div className={styles['title']}>
                Welcome
                <br />
                back!
            </div>
            <div className={styles['buttons']}>
                {DO_WE_SHOW_GOOGLE &&
                    <Button
                        size='m'
                        type='dark'
                        iconName='google'
                        onClick={() => {
                        }}
                    >
                        Continue with Google
                    </Button>
                }
                <Button
                    size='m'
                    type='dark'
                    iconName='metamask'
                    onClick={connectMetamask}
                >
                    Continue with Metamask
                </Button>
                {metamaskRejected && (
                    <div className={styles['cancel-request']}>
                        <Attention color='red'>
                            You rejected the wallet connection. Please approve in Metamask
                        </Attention>
                    </div>
                )}
            </div>
            <div className={styles['help']}>
                <span>New to Drizzle? </span>
                <span
                    className={styles['help__signup']}
                    onClick={navigateToSignUp}>
                    Sign up here
                </span>
            </div>
        </div>
    );
}

