import React from 'react'
import styles from './FormEdit.module.scss'
import profile from 'assets/image50.png'
import { Button } from 'components/Button/Button'
import { Input } from 'components/Input/Input'
import { COLORS_DATA } from './consts'
import { Icon } from 'components/Icon/Icon'
import { MultiRowInput } from 'components/MultiRowInput/MultiRowInput'
import { Chips } from 'components/Chips/Chips'
import {BackgroundColor, User} from "shared/db-types";
import {LinkInput} from "../../../LinkInput/LinkInput";


type Props = {
    onChange?: (credential: any) => void,
    title: string,
    user: User,
}

export function FormEdit(props: Props) {
    const fileInputRef = React.useRef(null);

    function handleCredentials(target) {
        props.onChange((user) => ({
            ...user,
            [target.name]: target.value,
        }));
    }

    function handleLinkChange(newLink: string) {
        props.onChange((user) => ({
            ...user,
            link: newLink,
        }));
    }

    function handleChip(param: string, val: string) {
        props.onChange((user) => ({
            ...user,
            [param]: val,
        }))
    }

    function handleItemClick(item) {
        props.onChange((user) => ({
            ...user,
            background: item.color
        }));
    }

    function handleFileChange(event) {
        const photo = event.target.files[0]
        props.onChange((user) => ({
            ...user,
            photo: photo,
        }));
    }

    function handleFileUpload() {
        fileInputRef.current.click();
        // Здесь можно добавить код для загрузки файла на сервер
        if (props.user.photo) {
            console.log("File uploaded:", props.user.photo)
            // Добавьте здесь логику для отправки файла на сервер
        } else {
            console.log("File not selected")
        }
    }

    return (
        <div className={styles['wrapper']}>
            <span className={styles['title']}>{props.title}</span>
            <div className={styles['profile']}>
                <img src={profile} className={styles['img']} alt='logo_mini' />
                <div className={styles['profile__wrapper']}>
                    <span className={styles['description']}>
                        Image should be at least 1024×1024px and must be in JPG or PNG format.
                    </span>
                    <div className={styles['buttons']}>
                        <Button
                            size='xs'
                            type='border'
                            stretch={false}
                            onClick={handleFileUpload}>
                            Upload
                        </Button>
                        <Button
                            size='xs'
                            type='border'
                            stretch={false}
                            onClick={() => { }}>
                            Remove
                        </Button>
                    </div>
                </div>
            </div>
            <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <Input
                size='m'
                label='Name'
                type='text'
                name='username'
                value={props.user.username}
                onChange={handleCredentials}
            />
            <LinkInput
                linkPrefix={"drizzle.finance/"}
                value={props.user.link}
                label='Drizzle page link'
                name='link'
                onChange={handleLinkChange}
                />
            <MultiRowInput
                label='About'
                name='bio'
                value={props.user.bio}
                onChange={handleCredentials}
            />
            <div>
                <span className={styles['label']}>Background</span>
                <div className={styles['colors']}>
                    {COLORS_DATA.map((item) => {
                        return (
                            <div
                                key={item.id}
                                className={styles['colors__item']}
                                onClick={() => handleItemClick(item)}
                                style={{ backgroundColor: item.value }}>
                                {(props.user.background && (props.user.background === item.color)) && <Icon name='done' />}
                                {(!props.user.background && BackgroundColor.Orange === item.color)  && <Icon name='done' />}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div>
                <span className={styles['label']}>Social links</span>
                <div className={styles['chips']}>
                    <Chips
                        name='twitter'
                        title='X'
                        iconName='twitter'
                        value={props.user.twitter}
                        onSave={(v) => handleChip('twitter', v)}
                    />
                    <Chips
                        name='instagram'
                        title='Instagram'
                        iconName='instagram'
                        value={props.user.instagram}
                        onSave={(v) => handleChip('instagram', v)}
                    />
                    <Chips
                        name='facebook'
                        title='Facebook'
                        iconName='facebook'
                        value={props.user.facebook}
                        onSave={(v) => handleChip('facebook', v)}
                    />
                </div>
            </div>
        </div>
    );
}