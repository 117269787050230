import styles from './SignupStep3Patron.module.scss'
import { Button } from 'components/Button/Button'
import {Input} from "components/Input/Input";
import React from "react";
import {EmptyUser} from "../../../../../shared/db-types";
import {useWeb3} from "components/Web3Context/Web3Context";
import {useDBUser} from "../../../../../components/UserInfoContext/UserInfoContext";


type Props = {
    onSubmit?: () => void,
    step?: number,
}

export function SignupStep3Patron(props: Props) {
    const [editedUser, setEditedUser] = React.useState(EmptyUser);
    const [username, setUsername] = React.useState('Anonymous');

    const { user, saveUserToDB } = useDBUser();
    const { userAddress } = useWeb3();

    async function handleName() {
        const updUser = {
            ...editedUser,
            username: username,
            link: userAddress.toLowerCase()
        };
        await saveUserToDB(updUser);
        props.onSubmit()
    }

    async function handleAnonymous() {
        const updUser = {
            ...editedUser,
            link: userAddress.toLowerCase()
        };
        await saveUserToDB(updUser);
        props.onSubmit();
    }

    React.useEffect(() => {
        setEditedUser(user);
    }, []);

    return (
        <div className={styles['wrapper']}>
            <div className={styles['title']}>
                Put a name to the face?
            </div>
            <div className={styles['description']}>
                You can save your name to tell the people you will support.
                <br />
                Or you can stay anonymous, that's cool, too.
            </div>
            <div className={styles['buttons']}>
                <Input
                    size='m'
                    type='text'
                    name='username'
                    placeholder='Anonymous'
                    value={username}
                    onChange={target => setUsername(target.value)}
                />
                <Button
                    size='m'
                    type='dark'
                    iconName='forward'
                    iconRight
                    onClick={handleName}
                >
                    Start with a name
                </Button>
                <Button
                    size='m'
                    type='dark'
                    iconName='forward'
                    iconRight
                    onClick={handleAnonymous}
                >
                    Remain anonymous
                </Button>
            </div>
        </div >
    );
}

