import styles from './Auth.module.scss'
import React from 'react';
import { AuthForm } from './AuthForm/AuthForm'
import { AuthNavBar } from './AuthNavbar/AuthNavBar'
import mobileFrame from 'assets/mobile_frame.png'
import login from 'assets/login.png'
import claimPreview from 'assets/claimPreview.png'
import classnames from 'classnames'

const PAGE_TITLE = "Getting you in | Drizzle. Sponsor without spending";

type Props = {
    authType: 'login' | 'signup';
}

type DisplayPicture = 'phone' | 'claimCard' | 'default';

export function Auth(props: Props) {
    const [picture, setPicture] = React.useState<DisplayPicture>('default')
    const [showNavBar, setShowNavBar] = React.useState(true);

    const hideNavBar = () => {
        setShowNavBar(false);
    }

    const returnNavBar = () => {
        setShowNavBar(true);
    }

    const showClaimCard = () => {
        setPicture('claimCard');
    }

    const showDefault = () => {
        setPicture('default');
    }

    const showPhone = () => {
        setPicture('phone');
    }

    const logoClassName = classnames({
        [styles['logo']]: true,
        [styles['logo_pink']]: picture !== 'default'
    })

    React.useEffect(() => {
        document.title = PAGE_TITLE;
    }, []);

    return (
        <div className={styles['wrapper']}>
            {showNavBar && <AuthNavBar authType={props.authType} />}
            <div className={styles['content']}>
                <AuthForm
                    authType={props.authType}
                    showClaimCard={showClaimCard}
                    showDefault={showDefault}
                    showPhone={showPhone}
                    hideNavBar={hideNavBar}
                    returnNavBar={returnNavBar}
                />
            </div>
            <div className={logoClassName}>
                {(picture === 'default') &&
                    <img
                        src={login}
                        className={styles['img']}
                        alt='drizzle login'
                    />
                }
                {(picture === 'phone') &&
                    <div className={styles['mobile']}>
                        <img
                            src={mobileFrame}
                            alt='drizzle mobile'
                        />
                    </div>
                }
                {(picture === 'claimCard') &&
                    <div className={styles['mobile']}>
                        <img
                            src={claimPreview}
                            alt='drizzle claim'
                        />
                    </div>
                }
            </div>
        </div>
    )
}

