import React from 'react'
import styles from './Profile.module.scss'
import { UserInfo } from 'components/UserInfo/UserInfo'
import { Button } from 'components/Button/Button'
import { CopyLink } from 'components/CopyLink/CopyLink'
import {getCurrentWalletConnected} from "components/helpers";
import {readUserByAddress} from "components/userAPI";
import {EmptyUser, User} from "shared/db-types";

type Props = {
    isPublic?: boolean,
    user: User,
    onCustomize?: () => void,
    isPreview?: boolean,
}

export function Profile(props: Props) {

    if (!props.isPublic) {
        return (
            <div className={styles['wrapper']}>
                <div>
                    <div className={styles['title']}>
                        Drizzle page
                    </div>
                    <UserInfo isPublic={props.isPublic} user={props.user} />
                </div>
                <div className={styles['button-anonymous']}>
                    <Button size='m' type='primary'>
                        Publish your page
                    </Button>
                    <span className={styles['help-anonymous']}>Publish your Drizzle page to share with your fans on social media</span>
                </div>
            </div>
        )
    }

    return (
        <div className={styles['wrapper']}>
            <div>
                <div className={styles['title']}>
                    Drizzle page
                </div>
                <div className={styles['content']}>
                    <UserInfo isPublic={props.isPublic} user={props.user} />
                    <CopyLink link={process.env.REACT_APP_BASE_URL + "/u/" + props.user.link} isPreview={props.isPreview} />
                </div>
            </div>
            <div className={styles['button']}>
                <Button
                    size='m'
                    type='light'
                    iconName='edit'
                    stretch={false}
                    onClick={props.onCustomize}
                    isPreview={props.isPreview}
                >
                    Customize
                </Button>
            </div>
        </div>
    );
}

