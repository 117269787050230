import React from 'react'
import { LoginContent } from './LoginContent/LoginContent'
import { SignupContent } from './SignupContent/SignupContent'

type Props = {
    authType: 'login' | 'signup';
    hideNavBar: () => void,
    returnNavBar: () => void,
    showClaimCard: () => void,
    showDefault: () => void,
    showPhone: () => void,
}

export function AuthForm(props: Props) {

    function switchContent(type) {
        switch (type) {
            case 'login':
                return <LoginContent />

            case 'signup':
                return <SignupContent
                    showClaimCard={props.showClaimCard}
                    showDefault={props.showDefault}
                    showPhone={props.showPhone}
                    hideNavBar={props.hideNavBar}
                    returnNavBar={props.returnNavBar}
                />
        }
    }

    return (
        <>
            {switchContent(props.authType)}
        </>
    );
}

