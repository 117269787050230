import React, {useEffect, useState} from 'react'
import styles from './CopyLink.module.scss'
import {CopyButton} from "../CopyButton/CopyButton";

type Props = {
    link: string;
    isPreview?: boolean;
}

export function CopyLink(props: Props) {
    return (
        <div className={styles['wrapper']}>
            <input
                type="text"
                readOnly
                value={props.link}
                className={styles['field']}
            />
            <div style={{width: '120px', minWidth: '120px'}}>
                <CopyButton
                    textToCopy={props.link}
                    copiedStyle={'white'}
                    idleStyle={'primary'}
                    idleCaption={'Copy link'}
                    size={"xs_tall"}
                    isPreview={props.isPreview}
                />
            </div>
        </div>
    );
}

