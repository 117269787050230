import React from 'react'
import { Button } from 'components/Button/Button'
import styles from './SignupStep1.module.scss'
import { useNavigate } from 'react-router-dom'
import { PopupAddMetamask } from './PopupAddMetamask/PopupAddMetamask'
import { Attention } from 'components/Attention/Attention'
import {useWeb3, ConnectMetamaskResponse} from "components/Web3Context/Web3Context";

const DO_WE_SHOW_GOOGLE = false;
const LOGIN_ROUTE = '/auth/login';

type Props = {
    onSubmit?: () => void;
}

export function SignupStep1(props: Props) {
    const [showPopupMetamask, setShowPopupMetamask] = React.useState(false);
    const [metamaskRejected, setMetamaskRejected] = React.useState(false);

    const navigate = useNavigate()
    const { connectWeb3, isConnected } = useWeb3();

    function navigateToLogin() {
        navigate(LOGIN_ROUTE);
    }

    function handleSubmit() {
        props.onSubmit();
    }

    async function connectMetamask() {
        const response: ConnectMetamaskResponse = await connectWeb3();
        if (response === "rejected") {
            setMetamaskRejected(true);
        } else if (response === "success") {
            props.onSubmit();
        }
    }

    function openPopupMetamask() {
        setShowPopupMetamask(true);
    }

    function closePopupMetamask() {
        setShowPopupMetamask(false);
    }

    return (
        <>
            <div className={styles['wrapper']}>
                <div className={styles['title']}>Sign up for free</div>
                <div className={styles['description']}>
                    We recommend to authorize with Metamask to send or receive support immediately.
                    <br />
                    <br />
                    Soon, we will add an option to just start with Google.</div>
                <div className={styles['buttons']}>
                    {DO_WE_SHOW_GOOGLE &&
                        <Button
                            size='m'
                            type='dark'
                            iconName='google'
                            onClick={handleSubmit}
                        >
                            Continue with Google
                        </Button>
                    }
                    {!isConnected &&
                        <Button
                            size='m'
                            type='dark'
                            iconName='metamask'
                            onClick={connectMetamask}
                        >
                            Continue with Metamask
                        </Button>
                    }
                    {isConnected &&
                    <Button
                        size='m'
                        type='dark'
                        iconName='metamask'
                        onClick={props.onSubmit}
                    >
                        Metamask connected. Continue!
                    </Button>
                    }
                    {metamaskRejected && (
                        <div className={styles['cancel-request']}>
                            <Attention color='red'>
                                You rejected wallet connection. Please approve in Metamask
                            </Attention>
                        </div>
                    )}
                </div>
                <div className={styles['help']}>
                    <span>Already have an account? </span>
                    <span
                        className={styles['help__signin']}
                        onClick={navigateToLogin}>
                        Sign in here
                    </span>
                </div>
            </div>
            {showPopupMetamask && <PopupAddMetamask onClose={closePopupMetamask} />}
        </>
    );
}

