import styles from './FAQ.module.scss'
import { Icon } from 'components/Icon/Icon'
import { Link } from 'react-router-dom'
import { Button } from 'components/Button/Button'

const FAQ_URL = "https://bubbly-echium-905.notion.site/Drizzle-FAQ-c5fc9000e3e74670b3510679b209d19a";

export function FAQ() {

    function navigateToFAQ() {
        window.open(FAQ_URL, '_blank')
    }

    return (
        <div className={styles['wrapper']}>
            <div>
                <Icon name='faq' />
                <div className={styles['description']}>
                    Support any creator
                    <br />
                    through Drizzle
                </div>
                <ul className={styles['list']}>
                    <li>Deposit crypto dollars into an interest protocol</li>
                    <li>Send a link to someone</li>
                    <li>They can claim interest from your deposit</li>
                    <li>You can withdraw the deposit anytime</li>
                </ul>
            </div>
            <div>
                <Button
                    size='s'
                    type='light'
                    iconName='external'
                    iconRight={true}
                    stretch={false}
                    onClick={navigateToFAQ}>
                    See full FAQ
                </Button>
            </div>
        </div>
    )
}

